<template>
<h1>Join a Hunt Goes Here</h1>
</template>

<script>
export default {
  name: "Join"
}
</script>

<style scoped>

</style>